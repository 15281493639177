import React from "react";
import ReactApexChart from "react-apexcharts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlaneSlash } from "@fortawesome/free-solid-svg-icons";

const AircraftOutOfCommissionChart = () => {
  // Fake data for aircraft out of commission
  const aircraftData = [
    { airline: "Airline A", outOfCommission: 3 },
    { airline: "Airline B", outOfCommission: 5 },
    { airline: "Airline C", outOfCommission: 2 },
    { airline: "Airline D", outOfCommission: 7 },
  ];

  // Chart options
  const options = {
    chart: {
      type: "bar",
      height: 300,
      toolbar: {
        show: false,
      },
    },
    colors: ["#6F42C1"], // Purple color
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: true,
      formatter: (val) => `${val} aircraft`,
      style: {
        colors: ["white"],
        fontSize: "12px",
        fontWeight: "bold",
      },
    },
    xaxis: {
      categories: aircraftData.map((data) => data.airline),
      labels: {
        style: {
          colors: "#6F42C1",
        },
      },
    },
    yaxis: {
      title: {
        text: "Number of Aircraft",
        style: {
          color: "#6F42C1",
        },
      },
      labels: {
        style: {
          colors: "#6F42C1",
        },
      },
    },
    tooltip: {
      theme: "dark",
      y: {
        formatter: (val) => `${val} aircraft`,
      },
    },
    legend: {
      show: false,
    },
  };

  // Chart series
  const series = [
    {
      name: "Aircraft Out of Commission",
      data: aircraftData.map((data) => data.outOfCommission),
    },
  ];

  return (
    <div className="bg-white rounded-lg shadow-lg p-3 h-full w-full flex flex-col">
      {/* Header */}
      <div className="flex items-center mb-4">
        <FontAwesomeIcon icon={faPlaneSlash} className="text-purple-600 text-2xl mr-3" />
        <h2 className="text-2xl font-bold text-purple-900">Aircraft Out of Commission</h2>
      </div>

      {/* Bar Chart */}
      <div className="flex-1">
        <ReactApexChart
          options={options}
          series={series}
          type="bar"
          height="100%"
        />
      </div>
    </div>
  );
};

export default AircraftOutOfCommissionChart;