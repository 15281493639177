import React from "react";
import Sidebar from "../Components/Sidebar";
import Header from "../Components/Header";
import SlidingText from "../Components/SlidedText";
import KPICard from "../Components/KpiCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faExclamationCircle,
  faCheckCircle,
  faStopwatch,
  faShieldAlt,
  faTools,
  faDoorOpen,
  faCouch,
  faClock,
  faChartLine,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import AircraftOutOfCommissionChart from "../Charts/AircraftOutOfCommissionChart";
import GroundVehicleAccidentsChart from "../Charts/GroundVehicleAccidentsChart";
import PassengerCountExceedingChart from "../Charts/PassengerCountExceedingChart";
import BaggageIssuesChart from "../Charts/BaggageIssuesChart";
import SecurityWaitTimesChart from "../Charts/SecurityWaitTimesChart";
import GateCongestionHeatmap from "../Charts/GateCongestionHeatmap";
import LoungeOccupancy from "../Charts/LoungeOccupancy";
import SecurityCheckpointWaitTimes from "../Charts/SecurityCheckpointWaitTimes";
import PassengersProcessed from "../Charts/PassengersProcessed";
import OpenCountersPerAirline from "../Charts/OpenCountersPerAirline";
import ActiveEGates from "../Charts/ActiveEGates";
import AverageProcessingTime from "../Charts/AverageProcessingTime";

const Passengerflow = () => {
  return (
    <div className="flex">
      <Sidebar />

      <div className="flex-1 bg-slate-200">
        <Header />

        <div className="p-1 bg-slate-200">
          <div className="col-span-5 row-span-3  h-30 bg-slate-200">
          <div className="grid grid-cols-6 gap-2 h-full bg-slate-200">
  {/* Total Passengers Processed */}
  <KPICard
    title="Total Passengers Processed"
    value="1,234"
    icon={<FontAwesomeIcon icon={faUsers} className="text-purple-600 w-6 h-6" />}
  />

  {/* Peak Hour Passengers */}
  <KPICard
    title="Peak Hour Passengers"
    value="456"
    icon={<FontAwesomeIcon icon={faChartLine} className="text-purple-600 w-6 h-6" />}
  />

  {/* Average Wait Time */}
  <KPICard
    title="Avg. Wait Time"
    value="8m"
    icon={<FontAwesomeIcon icon={faClock} className="text-purple-600 w-6 h-6" />}
  />

  {/* Security Checkpoint Throughput */}
  <KPICard
    title="Security Throughput"
    value="120/min"
    icon={<FontAwesomeIcon icon={faShieldAlt} className="text-purple-600 w-6 h-6" />}
  />

  {/* E-Gate Utilization */}
  <KPICard
    title="E-Gate Utilization"
    value="85%"
    icon={<FontAwesomeIcon icon={faDoorOpen} className="text-purple-600 w-6 h-6" />}
  />

  {/* Lounge Occupancy */}
  <KPICard
    title="Lounge Occupancy"
    value="70%"
    icon={<FontAwesomeIcon icon={faCouch} className="text-purple-600 w-6 h-6" />}
  />
</div>
            
          </div>

          {/* Sliding Text Component */}
        </div>
        <div className="flex space-x-2 ml-2 mr-2 mt-2">
        <div className="flex-1 h-96">

          <LoungeOccupancy />
          </div>
          <div className="flex-1 h-96">

          <SecurityCheckpointWaitTimes />
          </div>
          <div className="flex-1 h-96">

          <PassengersProcessed/>
          </div>
        </div>
        <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-2 mt-2  ml-2 mr-2">
  <div className="flex-1 h-96">
    <OpenCountersPerAirline />
  </div>
  <div className="flex-1 h-96">
    <ActiveEGates />
  </div>
  <div className="flex-1 h-96">
    <AverageProcessingTime />
  </div>
</div>


        </div>
        
      </div>
  );
};

export default Passengerflow;