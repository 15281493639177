import React from "react";
import Sidebar from "../Components/Sidebar";
import Header from "../Components/Header";
import SlidingText from "../Components/SlidedText";
import KPICard from "../Components/KpiCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faVideo,
  faUsers,
  faUserSlash,
  faFire,
  faSuitcase,
  faBan,
  faUserSecret,
  faParking,
  faShieldAlt,
  faUserCheck,
} from "@fortawesome/free-solid-svg-icons";
import VideoAnalyticAlertCount from "../Charts/security/VideoAnalyticAlertCount";
import Overcrowding from "../Charts/security/Overcrowding";
import BlackListedSuspect from "../Charts/security/BlackListedSuspect";
import FireSmokeDetection from "../Charts/security/FireSmokeDetection";
import BaggageLeftBehind from "../Charts/security/BaggageLeftBehind";
import UnauthorizedAreaAccess from "../Charts/security/UnauthorizedAreaAccess";
import SuspectedPerson from "../Charts/security/SuspectedPerson";
import AirportParkingCount from "../Charts/security/AirportParkingCount";
import FireEvents from "../Charts/security/FireEvents";
import EmployeeAccess from "../Charts/security/EmployeeAccess";



const SecuritySystem = () => {
  return (
    <div className="flex">
      <Sidebar />

      <div className="flex-1 bg-slate-200">
        <Header />

        <div className="p-1">
          {/* KPI Cards Section */}
          <div className="col-span-5 row-span-3  h-30">
            <div className="grid grid-cols-6 gap-2 h-full">
              {/* Video Analytic Alerts */}
              <KPICard
                title="Video Analytic Alerts"
                value="45"
                icon={<FontAwesomeIcon icon={faVideo} className="text-purple-600 w-6 h-6" />}
              />

              {/* Overcrowding Incidents */}
              <KPICard
                title="Overcrowding Incidents"
                value="12"
                icon={<FontAwesomeIcon icon={faUsers} className="text-purple-600 w-6 h-6" />}
              />

              {/* Blacklisted Suspects Detected */}
              <KPICard
                title="Blacklisted Suspects"
                value="5"
                icon={<FontAwesomeIcon icon={faUserSlash} className="text-purple-600 w-6 h-6" />}
              />

              {/* Fire/Smoke Detections */}
              <KPICard
                title="Fire/Smoke Detections"
                value="3"
                icon={<FontAwesomeIcon icon={faFire} className="text-purple-600 w-6 h-6" />}
              />

              {/* Baggage Left Behind */}
              <KPICard
                title="Baggage Left Behind"
                value="8"
                icon={<FontAwesomeIcon icon={faSuitcase} className="text-purple-600 w-6 h-6" />}
              />

              {/* Unauthorized Access Attempts */}
              <KPICard
                title="Unauthorized Access"
                value="10"
                icon={<FontAwesomeIcon icon={faBan} className="text-purple-600 w-6 h-6" />}
              />
            </div>
          </div>

          {/* First Row of Charts */}
          <div className="flex space-x-2  mt-2">
            <div className="flex-1 h-96">
              <VideoAnalyticAlertCount />
            </div>
            <div className="flex-1 h-96">
              <Overcrowding />
            </div>
            <div className="flex-1 h-96">
              <BlackListedSuspect />
            </div>
            <div className="flex-1 h-96">
              <FireSmokeDetection />
            </div>
            {/* <div className="flex-1 h-80">
              <BaggageLeftBehind />
            </div> */}
          </div>

          {/* Second Row of Charts */}
          <div className="flex space-x-2 mt-2">
          <div className="flex-1 h-96">
              <FireEvents />
            </div>
          
            <div className="flex-1 h-96">
              <UnauthorizedAreaAccess />
            </div>
            <div className="flex-1 h-96">
              <SuspectedPerson />
            </div>
            
            <div className="flex-1 h-96">
              <AirportParkingCount />
            </div>
            {/* <div className="flex-1 h-80">
              <EmployeeAccess />
            </div> */}
          </div>
         

         
      

        
        </div>
          {/* Sliding Text Component */}
          
      </div>
    </div>
  );
};

export default SecuritySystem;