import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlaneDeparture } from "@fortawesome/free-solid-svg-icons";
import Chart from "react-apexcharts";

const FlightDonutChart = () => {
  const [leftChartData, setLeftChartData] = useState(null);
  const [centerChartData, setCenterChartData] = useState(null);
  const [rightChartData, setRightChartData] = useState(null);

  useEffect(() => {
    // Fake data for Domestic Flights
    const domesticData = {
      series: [80, 15, 5], // On Time, Delayed, Cancelled
      labels: ["On Time", "Delayed", "Cancelled"],
    };

    // Fake data for Total Flights
    const totalData = {
      series: [70, 20, 10], // On Time, Delayed, Cancelled
      labels: ["On Time", "Delayed", "Cancelled"],
    };

    // Fake data for International Flights
    const internationalData = {
      series: [60, 30, 10], // On Time, Delayed, Cancelled
      labels: ["On Time", "Delayed", "Cancelled"],
    };

    // Set the fake data
    setLeftChartData(domesticData);
    setCenterChartData(totalData);
    setRightChartData(internationalData);
  }, []);

  const chartOptions = {
    chart: {
      type: "donut",
    },
    colors: ["#6F42C1", "#9F7AEA", "#D6BCFA"], // Purple gradient
    labels: [],
    legend: {
      position: "bottom",
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return `${parseFloat(val).toFixed(1)}%`;
      },
      style: {
        colors: ["#fff"], // White text for data labels
      },
    },
    plotOptions: {
      pie: {
        donut: {
          size: "50%", // Adjust the size of the donut hole
        },
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  return (
    <div className="bg-white rounded-lg shadow-lg p-3 h-full w-full flex flex-col">
      <div className="flex items-center mb-4">
        <FontAwesomeIcon
          icon={faPlaneDeparture}
          className="text-purple-600 text-2xl mr-3"
        />
        <h2 className="text-2xl font-bold text-purple-900">Flights Performance Overview</h2>
      </div>

      <div className="flex-1 flex flex-col md:flex-row justify-around items-center gap-6">
        {/* Left Chart - Domestic Flights */}
        {leftChartData && (
          <div className="w-full md:w-1/4">
            <Chart
              options={{ ...chartOptions, labels: leftChartData.labels }}
              series={leftChartData.series}
              type="donut"
              width="100%"
              height="300px" // Smaller height for side charts
            />
            <h3 className="text-center text-lg font-semibold text-purple-900 mt-2">
              Domestic Flights
            </h3>
          </div>
        )}

        {/* Center Chart - Total Flights */}
        {centerChartData && (
          <div className="w-full md:w-2/4">
            <Chart
              options={{ ...chartOptions, labels: centerChartData.labels }}
              series={centerChartData.series}
              type="donut"
              width="100%"
              height="400px" // Bigger height for center chart
            />
            <h3 className="text-center text-lg font-semibold text-purple-900 mt-2">
              Total Flights
            </h3>
          </div>
        )}

        {/* Right Chart - International Flights */}
        {rightChartData && (
          <div className="w-full md:w-1/4">
            <Chart
              options={{ ...chartOptions, labels: rightChartData.labels }}
              series={rightChartData.series}
              type="donut"
              width="100%"
              height="300px" // Smaller height for side charts
            />
            <h3 className="text-center text-lg font-semibold text-purple-900 mt-2">
              International Flights
            </h3>
          </div>
        )}
      </div>
    </div>
  );
};

export default FlightDonutChart;