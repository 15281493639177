import React from "react";
import ReactApexChart from "react-apexcharts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCouch } from "@fortawesome/free-solid-svg-icons";

const LoungeOccupancy = () => {
  // Fake data for lounge occupancy
  const occupancyData = {
    series: [
      { name: "Lounge A", data: [60, 70, 80] }, // Morning, Afternoon, Evening
      { name: "Lounge B", data: [50, 65, 75] },
      { name: "Lounge C", data: [70, 80, 90] },
    ],
    categories: ["Morning", "Afternoon", "Evening"],
  };

  // Chart options
  const options = {
    chart: { type: "heatmap", toolbar: { show: false } },
    colors: ["#6F42C1"], // Purple
    dataLabels: { enabled: true, formatter: (val) => `${val}%` },
    xaxis: { categories: occupancyData.categories },
    plotOptions: { heatmap: { shadeIntensity: 0.5 } },
    tooltip: { theme: "dark" },
  };

  return (
    <div className="bg-white rounded-lg shadow-lg p-3 h-full w-full flex flex-col">
      <div className="flex items-center mb-4">
        <FontAwesomeIcon icon={faCouch} className="text-purple-600 text-2xl mr-3" />
        <h2 className="text-2xl font-bold text-purple-900">Lounge Occupancy Levels</h2>
      </div>
      <ReactApexChart
        options={options}
        series={occupancyData.series}
        type="heatmap"
        height="230px"
      />
    </div>
  );
};

export default LoungeOccupancy;