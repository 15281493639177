import React, { useState, useEffect } from "react";

const Header = () => {
  const [currentTime, setCurrentTime] = useState("");

  // Fake weather data for KSA
  const fakeWeatherData = {
    temperature: "32°C", // Fake temperature
    condition: "Sunny", // Fake weather condition
    icon: "☀️", // Fake weather icon
  };

  // Function to update the current KSA time
  const updateTime = () => {
    const options = {
      timeZone: "Asia/Riyadh",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false, // Use 24-hour format
    };
    const formatter = new Intl.DateTimeFormat("en-US", options);
    const time = formatter.format(new Date());
    setCurrentTime(time);
  };

  // Update the time every second
  useEffect(() => {
    const interval = setInterval(updateTime, 1000);
    return () => clearInterval(interval); // Cleanup interval on unmount
  }, []);

  return (
    <header className="bg-white shadow-lg flex items-center justify-between border-b border-purple-200 h-20">
      {/* Company Logo and Title */}
      <div className="flex items-center">
        <h1 className="text-2xl font-bold text-purple-700 ml-4">Flight Dashboard</h1>
      </div>

      {/* Futuristic KSA Time and Weather Display */}
      <div className="flex items-center space-x-4 mr-4">
        {/* Weather Display */}
        <div className="flex items-center space-x-2 bg-purple-100 rounded-lg shadow-inner px-4 py-2">
          <span className="text-purple-700 text-2xl">{fakeWeatherData.icon}</span>
          <div className="text-purple-700 font-mono text-lg">
            {fakeWeatherData.temperature} | {fakeWeatherData.condition}
          </div>
        </div>

        {/* Time Display */}
        <div className="flex items-center space-x-2">
          <div className="text-purple-700 font-mono text-3xl font-bold bg-purple-100 rounded-lg shadow-inner px-4 py-2">
            {currentTime}
          </div>
          <div className="text-sm text-purple-500">KSA Time</div>
        </div>
      </div>
    </header>
  );
};

export default Header;