import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, GeoJSON, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import L from 'leaflet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkedAlt, faThermometerHalf, faTint, faWind } from '@fortawesome/free-solid-svg-icons';
import geoJsonData from './countries.geo.json';
import ReactApexChart from 'react-apexcharts';

const normalizeName = (name) => {
  return name ? name.trim().toLowerCase().replace(/[^a-z0-9]/g, '') : '';
};

// Function to determine color based on temperature
const getColor = (temp) => {
  return temp > 40 ? '#4A148C' : // Dark Purple (Hot)
         temp > 35 ? '#6A1B9A' : // Purple
         temp > 30 ? '#8E24AA' : // Light Purple
         temp > 25 ? '#AB47BC' : // Lighter Purple
         temp > 20 ? '#BA68C8' : // Very Light Purple
         temp > 15 ? '#CE93D8' : // Pale Purple
         temp > 10 ? '#E1BEE7' : // Very Pale Purple
                     '#E3F2FD';  // Light Blue (Cold)
};

// Legend component
const Legend = () => {
  const map = useMap();

  useEffect(() => {
    const legend = L.control({ position: 'bottomright' });

    legend.onAdd = () => {
      const div = L.DomUtil.create('div', 'info legend');
      const grades = [10, 15, 20, 25, 30, 35, 40];

      div.innerHTML = '<strong>Temperature (°C)</strong><br>';

      for (let i = 0; i < grades.length; i++) {
        const from = grades[i];
        const to = grades[i + 1];

        div.innerHTML +=
          `<div style="display: flex; align-items: center; margin-bottom: 4px;">
            <div style="width: 20px; height: 20px; background:${getColor(from + 1)}; margin-right: 8px;"></div>
            ${from}${to ? '&ndash;' + to : '+'}
          </div>`;
      }

      return div;
    };

    legend.addTo(map);

    return () => {
      legend.remove();
    };
  }, [map]);

  return null;
};

const Weathermap = () => {
  const [countryTemps, setCountryTemps] = useState({});

  useEffect(() => {
    // Generate random temperatures for all countries in the GeoJSON data
    const temps = geoJsonData.features.reduce((acc, feature) => {
      const countryName = feature.properties.name;
      const normalizedCountry = normalizeName(countryName);
      acc[normalizedCountry] = Math.floor(Math.random() * (41 - 10) + 10); // Generates 10-40

      return acc;
    }, {});

    setCountryTemps(temps);
  }, []);

  const onEachFeature = (feature, layer) => {
    const countryName = feature.properties.name;
    const normalizedGeoJsonCountry = normalizeName(countryName);
    const temp = countryTemps[normalizedGeoJsonCountry] || 0;

    layer.setStyle({
      fillColor: getColor(temp),
      weight: 1,
      opacity: 1,
      color: 'white',
      dashArray: '3',
      fillOpacity: 0.8,
    });

    layer.bindTooltip(
      `<strong>${countryName || "Unknown"}</strong><br>Temperature: ${temp}°C`,
      { sticky: true }
    );
  };

  return (
    <div className="p-4 rounded-lg shadow-lg bg-white text-gray-800" style={{ height: '100%', width: '100%' }}>
      <h2 className="text-md font-semibold mb-4 text-xl">
        <FontAwesomeIcon icon={faMapMarkedAlt} className="mr-2 text-purple-600 text-xl" />
        Global Weather Map
      </h2>

      {/* Full-screen Map Container */}
      <MapContainer
        key={JSON.stringify(countryTemps)}
        center={[20, 0]} // Centered globally
        zoom={2} // Zoomed out for global coverage
        style={{ height: "500px", width: "100%" }} // Full map height
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        <GeoJSON data={geoJsonData} onEachFeature={onEachFeature} />
        <Legend />
      </MapContainer>
    </div>
  );
};

export default Weathermap;
