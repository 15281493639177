import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlaneDeparture } from "@fortawesome/free-solid-svg-icons";
import flightData from "../data/flight_data.json"; // Import the JSON file

const FlightLineChart = () => {
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    // Process the imported JSON data
    const flightsPerWeek = flightData.reduce((acc, flight) => {
      const dateString = flight["Scheduled Departure"];

      // Skip invalid or missing dates
      if (!dateString || isNaN(new Date(dateString).getTime())) {
        console.warn(`Invalid date skipped: ${dateString}`);
        return acc;
      }

      const date = new Date(dateString);

      // Get the start of the week (Monday)
      const startOfWeek = new Date(date);
      startOfWeek.setDate(date.getDate() - date.getDay() + 1); // Adjust to Monday
      const weekKey = startOfWeek.toISOString().split("T")[0];

      acc[weekKey] = (acc[weekKey] || 0) + 1; // Increment the count for the week
      return acc;
    }, {});

    // Convert to array format for the chart
    const processedData = Object.keys(flightsPerWeek)
      .map((weekKey) => ({
        week: weekKey,
        flights: flightsPerWeek[weekKey],
      }))
      .sort((a, b) => new Date(a.week) - new Date(b.week));

    setChartData(processedData);
  }, []);

  // Chart options
  const options = {
    chart: {
      height: "100%",
      type: "line",
      zoom: {
        enabled: false, // Disable zooming
      },
      toolbar: {
        show: false, // Removed chart menu
      },
      dropShadow: {
        enabled: true,
        color: "#000",
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2,
      },
    },
    colors: ["#6F42C1"], // Purple color
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 3,
    },
    grid: {
      borderColor: "#e7e7e7",
      row: {
        colors: ["#f3f3f3", "transparent"],
        opacity: 0.5,
      },
    },
    markers: {
      size: 5,
      colors: ["#6F42C1"],
      strokeColors: "#fff",
      strokeWidth: 2,
      hover: {
        size: 7,
      },
    },
    xaxis: {
      categories: chartData.map((data) => data.week),
      labels: {
        style: {
          colors: "#6F42C1",
          fontSize: "8px",
        },
        formatter: function (value) {
          const date = new Date(value);
          return date.toLocaleDateString("en-US", {
            month: "short",
            day: "numeric",
          });
        },
      },
      title: {
        text: "Week",
        style: {
          color: "#6F42C1",
          fontSize: "8px",
        },
      },
    },
    yaxis: {
      title: {
        text: "Number of Flights",
        style: {
          color: "#6F42C1",
          fontSize: "8px",
        },
      },
      labels: {
        style: {
          colors: "#6F42C1",
        },
      },
    },
    tooltip: {
      theme: "dark",
      x: {
        formatter: function (value) {
          const startOfWeek = new Date(value);
          const endOfWeek = new Date(startOfWeek);
          endOfWeek.setDate(startOfWeek.getDate() + 6);
          const startDateStr = startOfWeek.toLocaleDateString("en-US", {
            month: "short",
            day: "numeric",
          });
          const endDateStr = endOfWeek.toLocaleDateString("en-US", {
            month: "short",
            day: "numeric",
          });
          return `${startDateStr} - ${endDateStr}`;
        },
      },
      y: {
        formatter: function (value) {
          return `${value} flights`;
        },
      },
    },
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "center",
      fontSize: "12px",
      fontFamily: "Arial, sans-serif",
      fontWeight: 400,
      itemMargin: {
        horizontal: 10,
        vertical: 5,
      },
    },
    responsive: [
      {
        breakpoint: 768,
        options: {
          chart: {
            height: "300px",
          },
          xaxis: {
            labels: {
              rotate: -45,
            },
          },
        },
      },
    ],
  };

  // Chart series
  const series = [
    {
      name: "Flights",
      data: chartData.map((data) => data.flights),
    },
  ];

  return (
    <div className="bg-white rounded-lg shadow-lg p-3 h-full w-full flex flex-col">
      {/* Title with Icon */}
      <div className="flex items-center mb-4">
        <FontAwesomeIcon
          icon={faPlaneDeparture}
          className="text-purple-600 text-2xl mr-3"
        />
        <h2 className="text-2xl font-bold text-purple-900">Flights Per Week</h2>
      </div>

      {/* ApexCharts Line Chart */}
      <div className="flex-1">
        <ReactApexChart
          options={options}
          series={series}
          type="line"
          height="100%"
        />
      </div>
    </div>
  );
};

export default FlightLineChart;
