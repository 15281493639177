import React from "react";
import ReactApexChart from "react-apexcharts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers } from "@fortawesome/free-solid-svg-icons";

const PassengerCountExceedingChart = () => {
  // Fake data for passenger count exceeding limits
  const passengerData = {
    series: [30, 40, 30], // Example data for areas A, B, C
    labels: ["Area A", "Area B", "Area C"],
  };

  // Chart options
  const options = {
    chart: {
      type: "pie",
      height: 250, // Reduced height for the chart
    },
    colors: ["#6F42C1", "#9F7AEA", "#D6BCFA"], // Purple gradient
    labels: passengerData.labels,
    legend: {
      position: "bottom",
    },
    dataLabels: {
      enabled: true,
      formatter: (val) => `${val}%`,
      style: {
        colors: ["#fff"],
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  return (
    <div className="bg-white rounded-lg shadow-lg p-4 h-full w-full flex flex-col">
      {/* Header */}
      <div className="flex items-center mb-4">
        <FontAwesomeIcon icon={faUsers} className="text-purple-600 text-2xl mr-3" />
        <h2 className="text-2xl font-bold text-purple-900">Passenger Count Exceeding Limits</h2>
      </div>

      {/* Pie Chart */}
      <div className="flex-1 flex items-center justify-center">
        <ReactApexChart
          options={options}
          series={passengerData.series}
          type="pie"
          height={250} // Fixed height for the chart
          className="w-full" // Ensure the chart fits the container width
        />
      </div>
    </div>
  );
};

export default PassengerCountExceedingChart;