import React from "react";
import ReactApexChart from "react-apexcharts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-solid-svg-icons";

const SecurityCheckpointWaitTimes = () => {
  // Fake data for wait times
  const waitTimesData = {
    series: [
      { name: "Checkpoint A", data: [5, 10, 15] }, // Average, Peak, Current
      { name: "Checkpoint B", data: [7, 12, 18] },
      { name: "Checkpoint C", data: [4, 8, 12] },
    ],
    categories: ["Average", "Peak", "Current"],
  };

  // Chart options
  const options = {
    chart: { type: "bar", toolbar: { show: false } },
    colors: ["#6F42C1", "#9F7AEA", "#D6BCFA"], // Purple gradient
    xaxis: { categories: waitTimesData.categories },
    plotOptions: { bar: { horizontal: false, columnWidth: "55%" } },
    dataLabels: { enabled: false },
    tooltip: { theme: "dark" },
  };

  return (
    <div className="bg-white rounded-lg shadow-lg p-3 h-full w-full flex flex-col">
      <div className="flex items-center mb-4">
        <FontAwesomeIcon icon={faClock} className="text-purple-600 text-2xl mr-3" />
        <h2 className="text-2xl font-bold text-purple-900">Security Checkpoint Wait Times</h2>
      </div>
      <ReactApexChart
        options={options}
        series={waitTimesData.series}
        type="bar"
        height="230px"
      />
    </div>
  );
};

export default SecurityCheckpointWaitTimes;