import React from "react";
import ReactApexChart from "react-apexcharts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faParking } from "@fortawesome/free-solid-svg-icons";

const AirportParkingCount = () => {
  const parkingData = {
    series: [70, 30], // Example data for Vacant, Occupied
    labels: ["Vacant", "Occupied"],
  };

  const options = {
    chart: {
      type: "donut",
      height: 350,
    },
    colors: ["#6F42C1", "#9F7AEA"],
    labels: parkingData.labels,
    legend: {
      position: "bottom",
    },
    dataLabels: {
      enabled: true,
      formatter: (val) => `${val}%`,
      style: {
        colors: ["#fff"],
      },
    },
  };

  return (
    <div className="bg-white rounded-lg shadow-lg p-4 h-full w-full flex flex-col">
      <div className="flex items-center mb-4">
        <FontAwesomeIcon icon={faParking} className="text-purple-600 text-2xl mr-3" />
        <h2 className="text-xl font-bold text-purple-900">Airport Parking Count</h2>
      </div>
      <div className="flex-1 flex items-center justify-center">
        <ReactApexChart options={options} series={parkingData.series} type="donut" height={250} />
      </div>
    </div>
  );
};

export default AirportParkingCount;