import React from "react";
import ReactApexChart from "react-apexcharts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDoorClosed } from "@fortawesome/free-solid-svg-icons";

const GateCongestionHeatmap = () => {
  // Fake data for congestion (0-100% capacity)
  const congestionData = {
    series: [
      { name: "Gate A", data: [80, 90, 75] }, // Morning, Afternoon, Evening
      { name: "Gate B", data: [60, 85, 70] },
      { name: "Gate C", data: [95, 65, 55] },
    ],
    categories: ["Morning", "Afternoon", "Evening"],
  };

  // Chart options
  const options = {
    chart: { type: "heatmap", toolbar: { show: false } },
    colors: ["#6F42C1"], // Purple
    dataLabels: { enabled: true, formatter: (val) => `${val}%` },
    xaxis: { categories: congestionData.categories },
    plotOptions: { heatmap: { shadeIntensity: 0.5 } },
    tooltip: { theme: "dark" },
  };

  return (
    <div className="bg-white rounded-lg shadow-lg p-3 h-full w-full flex flex-col">
      <div className="flex items-center mb-4">
        <FontAwesomeIcon icon={faDoorClosed} className="text-purple-600 text-2xl mr-3" />
        <h2 className="text-2xl font-bold text-purple-900">Gate Congestion</h2>
      </div>
      <ReactApexChart
        options={options}
        series={congestionData.series}
        type="heatmap"
        height="230px" // Fixed height
      />
    </div>
  );
};

export default GateCongestionHeatmap;