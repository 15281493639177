import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlaneDeparture } from "@fortawesome/free-solid-svg-icons";
import flightData from "../data/flight_data.json"; // Import the JSON file

const FlightMonthLineChart = () => {
  const [chartData, setChartData] = useState([]);
  const [topAirlines, setTopAirlines] = useState([]);

  useEffect(() => {
    // Process the imported JSON data
    const flightsPerMonthPerAirline = {};

    flightData.forEach((flight) => {
      const dateString = flight["Scheduled Departure"];
      const airline = flight["Airline"];

      // Skip invalid or missing dates
      if (!dateString || isNaN(new Date(dateString).getTime())) {
        console.warn(`Invalid date skipped: ${dateString}`);
        return;
      }

      const date = new Date(dateString);
      const monthKey = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}`; // Format: YYYY-MM

      // Initialize the month key if it doesn't exist
      if (!flightsPerMonthPerAirline[monthKey]) {
        flightsPerMonthPerAirline[monthKey] = {};
      }

      // Initialize the airline key if it doesn't exist
      if (!flightsPerMonthPerAirline[monthKey][airline]) {
        flightsPerMonthPerAirline[monthKey][airline] = 0;
      }

      // Increment the flight count for the airline in the month
      flightsPerMonthPerAirline[monthKey][airline] += 1;
    });

    // Get a list of all unique airlines and their total flights
    const airlineTotals = {};
    Object.values(flightsPerMonthPerAirline).forEach((monthData) => {
      Object.keys(monthData).forEach((airline) => {
        airlineTotals[airline] = (airlineTotals[airline] || 0) + monthData[airline];
      });
    });

    // Sort airlines by total flights and get the top 5
    const sortedAirlines = Object.keys(airlineTotals).sort(
      (a, b) => airlineTotals[b] - airlineTotals[a]
    );
    const topAirlinesList = sortedAirlines.slice(0, 5); // Show top 5 airlines
    setTopAirlines(topAirlinesList);

    // Group the remaining airlines into "Others"
    const processedData = Object.keys(flightsPerMonthPerAirline)
      .map((monthKey) => {
        const monthData = flightsPerMonthPerAirline[monthKey];
        const othersCount = Object.keys(monthData)
          .filter((airline) => !topAirlinesList.includes(airline))
          .reduce((sum, airline) => sum + monthData[airline], 0);

        return {
          month: monthKey,
          ...topAirlinesList.reduce((acc, airline) => {
            acc[airline] = monthData[airline] || 0;
            return acc;
          }, {}),
          Others: othersCount,
        };
      })
      .sort((a, b) => new Date(a.month) - new Date(b.month)); // Sort by month

    setChartData(processedData);
  }, []);

  // Chart options
  const options = {
    chart: {
      height: "100%",
      type: "line",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
      dropShadow: {
        enabled: true,
        color: "#000",
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2,
      },
    },
    colors: [
      "#6F42C1",
      "#FF4560",
      "#00E396",
      "#FEB019",
      "#775DD0",
      "#546E7A",
    ], // Different colors for each airline
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 3,
    },
    grid: {
      borderColor: "#e7e7e7",
      row: {
        colors: ["#f3f3f3", "transparent"],
        opacity: 0.5,
      },
    },
    markers: {
      size: 5,
      colors: ["#6F42C1"],
      strokeColors: "#fff",
      strokeWidth: 2,
      hover: {
        size: 7,
      },
    },
    xaxis: {
      categories: chartData.map((data) => data.month),
      labels: {
        style: {
          colors: "#6F42C1",
          fontSize: "8px",
        },
        formatter: function (value) {
          // Format the date to show the month and year
          const date = new Date(value);
          return date.toLocaleDateString("en-US", {
            year: "numeric",
            month: "short",
          });
        },
      },
    },
    yaxis: {
      title: {
        text: "Number of Flights",
        style: {
          color: "#6F42C1", // Purple color for y-axis title
        },
      },
      labels: {
        style: {
          colors: "#6F42C1", // Purple color for y-axis labels
        },
      },
    },
    tooltip: {
      theme: "dark",
      x: {
        formatter: function (value) {
          // Format the tooltip to show the full month and year
          const date = new Date(value);
          return date.toLocaleDateString("en-US", {
            year: "numeric",
            month: "long",
          });
        },
      },
    },
    legend: {
      show: true, // Show the legend
      position: "bottom", // Position the legend at the bottom
      horizontalAlign: "center", // Center-align the legend
      fontSize: "12px",
      fontFamily: "Arial, sans-serif",
      fontWeight: 400,
      itemMargin: {
        horizontal: 10, // Space between legend items
        vertical: 5,
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 100],
      },
    },
  };

  // Chart series
  const series = [...topAirlines, "Others"].map((airline) => ({
    name: airline,
    type: "line", // Line chart for each airline
    data: chartData.map((monthData) => monthData[airline] || 0), // Flight count for the airline in each month
  }));

  return (
    <div className="bg-white rounded-lg shadow-lg p-3 h-full w-full flex flex-col">
      {/* Title with Icon */}
      <div className="flex items-center mb-4">
        <FontAwesomeIcon
          icon={faPlaneDeparture}
          className="text-purple-600 text-2xl mr-3"
        />
        <h2 className="text-2xl font-bold text-purple-900">Flights Per Airline Per Month</h2>
      </div>

      {/* ApexCharts Line Chart */}
      <div className="flex-1">
        <ReactApexChart
          options={options}
          series={series}
          type="line"
          height="100%"
        />
      </div>
    </div>
  );
};

export default FlightMonthLineChart;