import React from "react";
import Sidebar from "../Components/Sidebar";
import Header from "../Components/Header";
import KPICard from "../Components/KpiCard";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faDoorOpen, faHourglassHalf, faMapMarkedAlt, faPercentage, faPlaneDeparture } from "@fortawesome/free-solid-svg-icons";
import FlightLineChart from "../Charts/FlightLineChart";
import FlightsPerAirlineBarChart from "../Charts/FlightsPerAirlineBarChart";
import FlightDonutChart from "../Charts/FlightDonutcharts";
import SlidingText from "../Components/SlidedText";
import RunwayAndGateUsage from "../Charts/RunwayAndGateUsage";
import FlightMonthLineChart from "../Charts/FlightlineMounthchart";

const Stc = () => {
  return (
    <div className="flex">
      {/* Sidebar */}
      <Sidebar />

      {/* Main Content */}
      <div className="flex-1 ">
        <div className="grid grid-cols-5 grid-rows-12">
          <div className="col-span-5 bg-white">
            <Header/>
          </div>
          <div className="col-span-5 row-span-3 bg-slate-200 p-2  h-30">
              <div className="grid grid-cols-6 gap-2 h-full">
              <KPICard
        title="Total Flights"
        value="100"
        icon={<FontAwesomeIcon icon={faPlaneDeparture} className="text-purple-600 w-6 h-6" />}
      />

      <KPICard
        title="On-Time Performance (%)"
        value="62 %"
        icon={<FontAwesomeIcon icon={faPercentage} className="text-purple-600 w-6 h-6" />}
      />

      <KPICard
        title="Delayed Flights"
        value="38"
        icon={<FontAwesomeIcon icon={faClock} className="text-purple-600 w-6 h-6" />}
      />

      <KPICard
        title="Active Terminal Gates"
        value="61"
        icon={<FontAwesomeIcon icon={faDoorOpen} className="text-purple-600 w-6 h-6" />}
      />

      <KPICard
        title="Active Runways"
        value="45"
        icon={<FontAwesomeIcon icon={faMapMarkedAlt} className="text-purple-600 w-6 h-6" />}
      />

      <KPICard
        title="Delayed Percentage (%)"
        value="38 %"
        icon={<FontAwesomeIcon icon={faHourglassHalf} className="text-purple-600 w-6 h-6" />}
      />

              </div>
</div>          
    <div className="col-span-5 row-span-4 bg-slate-200 ml-2 mr-2 h-96 grid grid-cols-3 gap-2">
      {/* flights Div */}
      <div className="bg-white flex justify-center items-center rounded-md shadow-sm">
      <FlightLineChart />
      </div>
      
      {/* Second Div */}
      <div className="bg-white flex justify-center items-center rounded-md shadow-sm">
        <FlightsPerAirlineBarChart/>
      </div>
      
      
      <div className="bg-white flex justify-center items-center rounded-md shadow-sm">
      <FlightDonutChart/>

      </div>
</div>

      <div className="col-span-5 row-span-4 bg-slate-200 p-2 h-96">
        <div className="flex h-full">
          {/* Left Div */}
          <div className="w-1/2 bg-white rounded-lg shadow-lg flex items-center justify-center mr-2">
            <RunwayAndGateUsage/>
          </div>
          {/* Right Div */}
          <div className="w-1/2 bg-white rounded-lg shadow-lg flex items-center justify-center ml-2">
          <FlightMonthLineChart/>
          </div>
        </div>
      </div>
      
        </div>
      </div>
    </div>
  );
};

export default Stc;