import React from "react";
import ReactApexChart from "react-apexcharts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan } from "@fortawesome/free-solid-svg-icons";

const UnauthorizedAreaAccess = () => {
  const accessData = {
    series: [8, 12, 15], // Example data for zones A, B, C
    labels: ["Zone A", "Zone B", "Zone C"],
  };

  const options = {
    chart: {
      type: "pie",
      height: 350,
    },
    colors: ["#6F42C1", "#9F7AEA", "#D6BCFA"],
    labels: accessData.labels,
    legend: {
      position: "bottom",
    },
    dataLabels: {
      enabled: true,
      formatter: (val) => `${val.toFixed(2)}%`, // Ensuring two decimal places
      style: {
        colors: ["#fff"],
      },
    },
  };

  return (
    <div className="bg-white rounded-lg shadow-lg p-4 h-full w-full flex flex-col">
      <div className="flex items-center mb-4">
        <FontAwesomeIcon icon={faBan} className="text-purple-600 text-2xl mr-3" />
        <h2 className="text-xl font-bold text-purple-900">Unauthorized Area Access</h2>
      </div>
      <div className="flex-1 flex items-center justify-center">
        <ReactApexChart options={options} series={accessData.series} type="pie" height={230} />
      </div>
    </div>
  );
};

export default UnauthorizedAreaAccess;
