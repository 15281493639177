import React from "react";
import ReactApexChart from "react-apexcharts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStopwatch } from "@fortawesome/free-solid-svg-icons";

const AverageProcessingTime = () => {
  // Fake data for average processing time
  const processingTimeData = {
    series: [2.5, 3.0, 2.8, 3.2, 2.9], // Average processing time in minutes
    categories: ["Checkpoint A", "Checkpoint B", "Checkpoint C", "Checkpoint D", "Checkpoint E"],
  };

  // Chart options
  const options = {
    chart: { type: "bar", toolbar: { show: false } },
    colors: ["#6F42C1"], // Purple
    xaxis: { categories: processingTimeData.categories },
    plotOptions: { bar: { horizontal: false, columnWidth: "55%" } },
    dataLabels: { enabled: true, formatter: (val) => `${val} mins` },
    tooltip: { theme: "dark" },
  };

  return (
    <div className="bg-white rounded-lg shadow-lg p-3 h-full w-full flex flex-col">
      <div className="flex items-center mb-4">
        <FontAwesomeIcon icon={faStopwatch} className="text-purple-600 text-2xl mr-3" />
        <h2 className="text-2xl font-bold text-purple-900">Average Processing Time Per Passenger</h2>
      </div>
      <ReactApexChart
        options={options}
        series={[{ data: processingTimeData.series }]}
        type="bar"
        height="230px"
      />
    </div>
  );
};

export default AverageProcessingTime;