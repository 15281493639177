import React from "react";
import ReactApexChart from "react-apexcharts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCarCrash } from "@fortawesome/free-solid-svg-icons";

const GroundVehicleAccidentsChart = () => {
  // Fake data for ground vehicle accidents
  const accidentData = [
    { day: "Day 1", accidents: 2 },
    { day: "Day 2", accidents: 3 },
    { day: "Day 3", accidents: 5 },
    { day: "Day 4", accidents: 1 },
    { day: "Day 5", accidents: 4 },
    { day: "Day 6", accidents: 6 },
    { day: "Day 7", accidents: 3 },
  ];

  // Chart options
  const options = {
    chart: {
      type: "line",
      height: 300,
      toolbar: {
        show: false,
      },
    },
    colors: ["#6F42C1"], // Purple color
    stroke: {
      curve: "smooth",
      width: 3,
    },
    xaxis: {
      categories: accidentData.map((data) => data.day),
      labels: {
        style: {
          colors: "#6F42C1",
        },
      },
    },
    yaxis: {
      title: {
        text: "Number of Accidents",
        style: {
          color: "#6F42C1",
        },
      },
      labels: {
        style: {
          colors: "#6F42C1",
        },
      },
    },
    tooltip: {
      theme: "dark",
      y: {
        formatter: (val) => `${val} accidents`,
      },
    },
    legend: {
      show: false,
    },
  };

  // Chart series
  const series = [
    {
      name: "Ground Vehicle Accidents",
      data: accidentData.map((data) => data.accidents),
    },
  ];

  return (
    <div className="bg-white rounded-lg shadow-lg p-3 h-full w-full flex flex-col">
      {/* Header */}
      <div className="flex items-center mb-4">
        <FontAwesomeIcon icon={faCarCrash} className="text-purple-600 text-2xl mr-3" />
        <h2 className="text-2xl font-bold text-purple-900">Ground Vehicle Accidents</h2>
      </div>

      {/* Line Chart */}
      <div className="flex-1">
        <ReactApexChart
          options={options}
          series={series}
          type="line"
          height="100%"
        />
      </div>
    </div>
  );
};

export default GroundVehicleAccidentsChart;