import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import flightData from "../data/flight_data.json"; // Import the JSON file

const FlightsPerAirlineBarChart = () => {
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    // Process the imported JSON data
    const flightsPerAirline = flightData.reduce((acc, flight) => {
      const airline = flight["Airline"];
      acc[airline] = (acc[airline] || 0) + 1; // Increment the count for the airline
      return acc;
    }, {});

    // Convert to array format for the chart and sort by flights in descending order
    const processedData = Object.keys(flightsPerAirline)
      .map((airline) => ({
        airline,
        flights: flightsPerAirline[airline],
      }))
      .sort((a, b) => b.flights - a.flights); // Sort by flights in descending order

    setChartData(processedData);
  }, []);

  // Chart options
  const options = {
    chart: {
      type: "bar",
      height: 400,
      toolbar: {
        show: false,
      },
    },
    colors: ["#6F42C1"], // Purple color
    plotOptions: {
      bar: {
        horizontal: true, // Horizontal bars
        columnWidth: "60%", // Adjust column width for better spacing
        endingShape: "rounded",
        dataLabels: {
          position: "center", // Center the values inside the bars
        },
      },
    },
    dataLabels: {
      enabled: true, // Enable data labels
      formatter: function (val) {
        return val; // Display the flight count value
      },
      style: {
        colors: ["#fff"], // White color for data labels to make them visible
        fontSize: "14px",
        fontWeight: "bold",
      },
      offsetX: 0, // Center horizontally
      offsetY: 0, // Center vertically
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: chartData.map((data) => data.airline),
      labels: {
        style: {
          colors: "#6F42C1", // Purple color for x-axis labels
        },
      },
    },
    yaxis: {
      title: {
        text: "Number of Flights",
        style: {
          color: "#6F42C1", // Purple color for y-axis title
        },
      },
      labels: {
        style: {
          colors: "#6F42C1", // Purple color for y-axis labels
        },
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      theme: "dark",
      y: {
        formatter: function (val) {
          return `${val} flights`;
        },
      },
    },
    legend: {
      show: false, // Hide the traditional legend
    },
  };

  // Chart series
  const series = [
    {
      name: "Flights",
      data: chartData.map((data) => data.flights),
    },
  ];

  return (
    <div className="bg-white rounded-lg shadow-lg p-3 h-full w-full flex flex-col">
      {/* Title */}
      <h2 className="text-2xl font-bold text-purple-900">Flights Per Airline</h2>

      {/* ApexCharts Bar Chart */}
      <div className="flex-1">
        <ReactApexChart options={options} series={series} type="bar" height="100%" />
      </div>
    </div>
  );
};

export default FlightsPerAirlineBarChart;
