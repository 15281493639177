import React from "react";
import ReactApexChart from "react-apexcharts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlaneArrival, faDoorOpen, faInfoCircle, faChartLine } from "@fortawesome/free-solid-svg-icons";

const RunwayAndGateUsage = () => {
  // Static data for runway and gate usage
  const runwayData = [
    { name: "Runway 1", value: 12 },
    { name: "Runway 2", value: 8 },
    { name: "Runway 3", value: 5 },
  ];

  const gateData = [
    { name: "Gate A", value: 15 },
    { name: "Gate B", value: 10 },
    { name: "Gate C", value: 7 },
  ];

  // Bar chart options for runway usage
  const barChartOptions = {
    chart: {
      type: "bar",
      toolbar: { show: false },
    },
    colors: ["#7E57C2"], // Medium purple color
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "45%",
        endingShape: "rounded",
        dataLabels: {
          position: "top", // Show data labels on top of bars
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: (val) => `${val} flights`, // Add units to data labels
      offsetY: -20,
      style: {
        fontSize: "12px",
        colors: ["#7E57C2"],
      },
    },
    xaxis: {
      categories: runwayData.map((item) => item.name), // Dynamically updated
      labels: {
        style: { colors: "#7E57C2" },
      },
    },
    yaxis: {
      title: {
        text: "Flights",
        style: { color: "#7E57C2" },
      },
      labels: {
        style: { colors: "#7E57C2" },
      },
    },
    tooltip: {
      theme: "dark",
      y: {
        formatter: (val) => `${val} flights`, // Add units to tooltips
      },
    },
    grid: {
      borderColor: "#e7e7e7",
      row: { colors: ["#f3f3f3", "transparent"], opacity: 0.5 },
    },
    legend: { show: false },
  };

  // Line chart options for gate utilization
  const lineChartOptions = {
    chart: {
      type: "line",
      toolbar: { show: false },
    },
    colors: ["#AB47BC"], // Lighter purple color
    stroke: {
      curve: "smooth", // Smooth line
      width: 2,
    },
    markers: {
      size: 5,
      colors: ["#AB47BC"],
    },
    xaxis: {
      categories: gateData.map((item) => item.name), // Dynamically updated
      labels: {
        style: { colors: "#AB47BC" },
      },
    },
    yaxis: {
      title: {
        text: "Flights",
        style: { color: "#AB47BC" },
      },
      labels: {
        style: { colors: "#AB47BC" },
      },
    },
    tooltip: {
      theme: "dark",
      y: {
        formatter: (val) => `${val} flights`, // Add units to tooltips
      },
    },
    grid: {
      borderColor: "#e7e7e7",
      row: { colors: ["#f3f3f3", "transparent"], opacity: 0.5 },
    },
    legend: { show: false },
  };

  return (
    <div className="bg-white rounded-lg shadow-lg p-4 h-full w-full flex flex-col">
      {/* Header with description */}
      <div className="mb-2">
        <h2 className="text-lg font-bold text-purple-900 mb-1 flex items-center">
          <FontAwesomeIcon icon={faChartLine} className="mr-2" />
          Runway and Gate Usage
        </h2>
        <p className="text-gray-600 flex items-center text-xs">
          <FontAwesomeIcon icon={faInfoCircle} className="mr-1" />
          Overview of runway and gate utilization for the current period.
        </p>
      </div>

      {/* Flex layout for side-by-side charts */}
      <div className="flex-1 grid grid-cols-2 gap-3">
        {/* Runway Usage Section (Bar Chart) */}
        <div className="h-full flex flex-col">
          <div className="flex items-center mb-1">
            <FontAwesomeIcon
              icon={faPlaneArrival}
              className="text-purple-600 text-md mr-2"
            />
            <h3 className="text-sm font-semibold text-purple-900">
              Runway Usage
            </h3>
          </div>
          <div className="flex-1 min-h-0"> {/* Ensure the chart fits within the container */}
            <ReactApexChart
              options={barChartOptions}
              series={[
                {
                  name: "Runway Usage",
                  data: runwayData.map((item) => item.value),
                },
              ]}
              type="bar"
              height="100%"
            />
          </div>
          <div className="mt-1 text-xs text-gray-600">
            <p>
              <span className="font-semibold">Most Used:</span> Runway 1 (
              {Math.max(...runwayData.map((item) => item.value))} flights)
            </p>
            <p>
              <span className="font-semibold">Least Used:</span> Runway 3 (
              {Math.min(...runwayData.map((item) => item.value))} flights)
            </p>
          </div>
        </div>

        {/* Gate Utilization Section (Line Chart) */}
        <div className="h-full flex flex-col">
          <div className="flex items-center mb-1">
            <FontAwesomeIcon
              icon={faDoorOpen}
              className="text-purple-600 text-md mr-2"
            />
            <h3 className="text-sm font-semibold text-purple-900">
              Gate Utilization
            </h3>
          </div>
          <div className="flex-1 min-h-0"> {/* Ensure the chart fits within the container */}
            <ReactApexChart
              options={lineChartOptions}
              series={[
                {
                  name: "Gate Utilization",
                  data: gateData.map((item) => item.value),
                },
              ]}
              type="line"
              height="100%"
            />
          </div>
          <div className="mt-1 text-xs text-gray-600">
            <p>
              <span className="font-semibold">Most Used:</span> Gate A (
              {Math.max(...gateData.map((item) => item.value))} flights)
            </p>
            <p>
              <span className="font-semibold">Least Used:</span> Gate C (
              {Math.min(...gateData.map((item) => item.value))} flights)
            </p>
          </div>
        </div>
      </div>

      {/* Footer with summary */}
     
    </div>
  );
};

export default RunwayAndGateUsage;