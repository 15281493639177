import React from "react";
import Sidebar from "../Components/Sidebar";
import Header from "../Components/Header";
import Weathermap from "../Charts/MapGuests"

import WeatherCard from "../Components/WeatherCard";
import WeatherDashboard from "../Charts/WeatherChart";
import SlidingText from "../Components/SlidedText";
import KPICard from "../Components/KpiCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloud, faCloudRain, faPlaneDeparture, faSun } from "@fortawesome/free-solid-svg-icons";

const Weather = () => {
  return (
    <div className="flex h-max-height">
      {/* Sidebar */}
      <Sidebar />

      {/* Main Content */}
      <div className="flex-1 bg-slate-200">
        {/* Header */}
        <Header />

        {/* Page Content */}
        <div className="p-1">
         

          {/* Daily Weather Forecast */}
          <div >
            
            <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-6 gap-2">
              {/* Example Weather Cards */}
              <KPICard
        title="Today"
        value="28°C"
        icon={<FontAwesomeIcon icon={faSun} className="text-purple-600 w-6 h-6" />}
      />
                     <KPICard
        title="Tomorrow"
        value="25°C"
        icon={<FontAwesomeIcon icon={faCloud} className="text-purple-600 w-6 h-6" />}
      />
                     <KPICard
        title="Wed"
        value="22°C"
        icon={<FontAwesomeIcon icon={faCloudRain} className="text-purple-600 w-6 h-6" />}
      />
                      <KPICard
        title="Thu"
        value="22°C"
        icon={<FontAwesomeIcon icon={faCloudRain} className="text-purple-600 w-6 h-6" />}
      />
            
            <KPICard
        title="Fri"
        value="22°C"
        icon={<FontAwesomeIcon icon={faCloudRain} className="text-purple-600 w-6 h-6" />}
      />
            
            <KPICard
        title="Sat"
        value="22°C"
        icon={<FontAwesomeIcon icon={faCloudRain} className="text-purple-600 w-6 h-6" />}
      />
            
              
             
            </div>
          </div>

          {/* Monthly Weather Forecast */}
          <div className="mb-8">
            
            <div className="p-2 rounded-lg shadow-md flex flex-row gap-2">
      {/* Weathermap Component */}
      <div className="flex-1">
        <Weathermap />
      </div>

      {/* WeatherChart Component */}
      <div className="flex-1">
        <WeatherDashboard />
      </div>
    </div>
   

          </div>

         
        </div>
      </div>
    </div>
  );
};

export default Weather;