import React from "react";
import ReactApexChart from "react-apexcharts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers } from "@fortawesome/free-solid-svg-icons";

const PassengersProcessed = () => {
  // Fake data for passengers processed
  const passengersData = {
    series: [
      { name: "Passengers Processed", data: [120, 150, 200, 180, 220, 250] }, // Per hour
    ],
    categories: ["9 AM", "10 AM", "11 AM", "12 PM", "1 PM", "2 PM"],
  };

  // Chart options
  const options = {
    chart: { type: "line", toolbar: { show: false } },
    colors: ["#6F42C1"], // Purple
    xaxis: { categories: passengersData.categories },
    stroke: { curve: "smooth", width: 3 },
    dataLabels: { enabled: false },
    tooltip: { theme: "dark" },
  };

  return (
    <div className="bg-white rounded-lg shadow-lg p-3 h-full w-full flex flex-col">
      <div className="flex items-center mb-4">
        <FontAwesomeIcon icon={faUsers} className="text-purple-600 text-2xl mr-3" />
        <h2 className="text-2xl font-bold text-purple-900">Passengers Processed Per Hour</h2>
      </div>
      <ReactApexChart
        options={options}
        series={passengersData.series}
        type="line"
        height="230px"
      />
    </div>
  );
};

export default PassengersProcessed;