import React from "react";
import ReactApexChart from "react-apexcharts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShieldAlt } from "@fortawesome/free-solid-svg-icons";

const SecurityWaitTimesChart = () => {
  // Fake data for wait times (in minutes)
  const waitTimes = [8, 12, 10, 15, 9, 11, 13];

  // Chart options
  const options = {
    chart: { type: "line", toolbar: { show: false } },
    colors: ["#9F7AEA"], // Light purple
    xaxis: {
      categories: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      labels: { style: { colors: "#9F7AEA" } },
    },
    yaxis: {
      title: { text: "Wait Time (mins)", style: { color: "#9F7AEA" } },
      labels: { style: { colors: "#9F7AEA" } },
    },
    stroke: { curve: "smooth", width: 3 },
    tooltip: { theme: "dark" },
  };

  return (
    <div className="bg-white rounded-lg shadow-lg p-3 h-full w-full flex flex-col">
      <div className="flex items-center mb-4">
        <FontAwesomeIcon icon={faShieldAlt} className="text-purple-600 text-2xl mr-3" />
        <h2 className="text-2xl font-bold text-purple-900">Security Wait Times</h2>
      </div>
      <ReactApexChart
        options={options}
        series={[{ name: "Wait Time", data: waitTimes }]}
        type="line"
        height="230px" // Fixed height
      />
    </div>
  );
};

export default SecurityWaitTimesChart;