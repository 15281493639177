import React from "react";
import Sidebar from "../Components/Sidebar";
import Header from "../Components/Header";
import SlidingText from "../Components/SlidedText";
import KPICard from "../Components/KpiCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faExclamationCircle,
  faCheckCircle,
  faStopwatch,
  faShieldAlt,
  faTools,
} from "@fortawesome/free-solid-svg-icons";
import AircraftOutOfCommissionChart from "../Charts/AircraftOutOfCommissionChart";
import GroundVehicleAccidentsChart from "../Charts/GroundVehicleAccidentsChart";
import PassengerCountExceedingChart from "../Charts/PassengerCountExceedingChart";
import BaggageIssuesChart from "../Charts/BaggageIssuesChart";
import SecurityWaitTimesChart from "../Charts/SecurityWaitTimesChart";
import GateCongestionHeatmap from "../Charts/GateCongestionHeatmap";

const Eventsandalerts = () => {
  return (
    <div className="flex">
      <Sidebar />

      <div className="flex-1 bg-slate-200">
        <Header />

        <div className="p-1">
          <div className="col-span-5 row-span-3 h-30">
            <div className="grid grid-cols-6 gap-2 h-full">
              {/* Total Active Alerts */}
              <KPICard
                title="Total Active Alerts"
                value="42"
                icon={<FontAwesomeIcon icon={faBell} className="text-purple-600 w-6 h-6" />}
              />

              {/* Critical Alerts */}
              <KPICard
                title="Critical Alerts"
                value="12"
                icon={<FontAwesomeIcon icon={faExclamationCircle} className="text-purple-600 w-6 h-6" />}
              />

              {/* Resolved Alerts (24h) */}
              <KPICard
                title="Resolved Alerts (24h)"
                value="30"
                icon={<FontAwesomeIcon icon={faCheckCircle} className="text-purple-600 w-6 h-6" />}
              />

              {/* Average Resolution Time */}
              <KPICard
                title="Avg. Resolution Time"
                value="15m"
                icon={<FontAwesomeIcon icon={faStopwatch} className="text-purple-600 w-6 h-6" />}
              />

              {/* Security Alerts */}
              <KPICard
                title="Security Alerts"
                value="8"
                icon={<FontAwesomeIcon icon={faShieldAlt} className="text-purple-600 w-6 h-6" />}
              />

              {/* Operational Alerts */}
              <KPICard
                title="Operational Alerts"
                value="22"
                icon={<FontAwesomeIcon icon={faTools} className="text-purple-600 w-6 h-6" />}
              />
            </div>
          </div>

          {/* Sliding Text Component */}
        </div>
        <div className="flex space-x-2 mr-2 ml-2 mt-1">
        <div className="flex-1 h-96">

          <AircraftOutOfCommissionChart />
          </div>
          <div className="flex-1 h-96">

          <GroundVehicleAccidentsChart />
          </div>
          <div className="flex-1 h-96">

          <PassengerCountExceedingChart />
          </div>
        </div>
        <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-2 mt-2  ml-2 mr-2">
  <div className="flex-1 h-96">
    <BaggageIssuesChart />
  </div>
  <div className="flex-1 h-96">
    <SecurityWaitTimesChart />
  </div>
  <div className="flex-1 h-96">
    <GateCongestionHeatmap />
  </div>
</div>

    

        </div>
        
      </div>
  );
};

export default Eventsandalerts;