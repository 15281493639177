import React from "react";
import ReactApexChart from "react-apexcharts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSuitcaseRolling } from "@fortawesome/free-solid-svg-icons";

const BaggageIssuesChart = () => {
  // Fake data for baggage mishandling
  const baggageData = [
    { airline: "Airline A", issues: 12 },
    { airline: "Airline B", issues: 8 },
    { airline: "Airline C", issues: 15 },
  ];

  // Chart options
  const options = {
    chart: {
      type: "bar",
      toolbar: { show: false },
    },
    colors: ["#6F42C1"], // Purple
    xaxis: {
      categories: baggageData.map((item) => item.airline),
      labels: { style: { colors: "#6F42C1" } },
    },
    yaxis: {
      title: { text: "Mishandled Bags", style: { color: "#6F42C1" } },
      labels: { style: { colors: "#6F42C1" } },
    },
    dataLabels: {
      enabled: true,
      formatter: (val) => `${val} bags`,
      style: { colors: ["white"], fontSize: "12px" },
    },
    tooltip: { theme: "dark" },
  };

  return (
    <div className="bg-white rounded-lg shadow-lg p-4 h-full w-full flex flex-col">
      {/* Header */}
      <div className="flex items-center mb-4">
        <FontAwesomeIcon icon={faSuitcaseRolling} className="text-purple-600 text-2xl mr-3" />
        <h2 className="text-2xl font-bold text-purple-900">Baggage Mishandling</h2>
      </div>

      {/* Bar Chart */}
      <div className="flex-1 flex items-center justify-center">
        <ReactApexChart
          options={options}
          series={[{ name: "Issues", data: baggageData.map((item) => item.issues) }]}
          type="bar"
          height={230} // Fixed height for the chart
          className="w-full" // Ensure the chart fits the container width
        />
      </div>
    </div>
  );
};

export default BaggageIssuesChart;