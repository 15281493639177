import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTint,
  faWind,
  faThermometerHalf,
} from "@fortawesome/free-solid-svg-icons";

// WeatherChart Component
const WeatherChart = ({ height = 180, data }) => {
  const options = {
    chart: {
      type: "area",
      height: height,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      animations: {
        enabled: true,
        easing: "easeinout",
        speed: 800,
      },
    },
    colors: ["#8B5CF6", "#A78BFA"], // Purple shades
    stroke: {
      curve: "smooth",
      width: 2,
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.3,
        stops: [0, 90, 100],
      },
    },
    xaxis: {
      categories: data.map((item) => item.month),
      labels: {
        style: {
          colors: "#6B7280",
          fontSize: "10px", // Smaller font size
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: "#6B7280",
          fontSize: "10px", // Smaller font size
        },
      },
    },
    tooltip: {
      theme: "light",
    },
    grid: {
      borderColor: "#E5E7EB",
      strokeDashArray: 4,
    },
    markers: {
      size: 4,
      colors: ["#8B5CF6"],
      strokeWidth: 0,
      hover: {
        size: 6,
      },
    },
  };

  const series = [
    {
      name: "Average Temperature",
      data: data.map((item) => item.temperature),
    },
    {
      name: "Precipitation (mm)",
      data: data.map((item) => item.precipitation),
    },
  ];

  return <ReactApexChart options={options} series={series} type="area" height={height} />;
};

// Gauge Component (Reusable for Humidity & AQI)
const Gauge = ({ height = 120, value, label }) => {
  const options = {
    chart: {
      type: "radialBar",
      height: height,
      animations: {
        enabled: true,
        easing: "easeinout",
        speed: 800,
      },
    },
    plotOptions: {
      radialBar: {
        startAngle: -135,
        endAngle: 135,
        hollow: {
          size: "60%",
        },
        dataLabels: {
          name: {
            show: false, // Hide the label
          },
          value: {
            fontSize: "16px", // Smaller font size
            color: "#8B5CF6", // Purple color
            offsetY: 5,
          },
        },
      },
    },
    fill: {
      colors: ["#8B5CF6"], // Purple color
    },
    labels: [label],
  };

  return <ReactApexChart options={options} series={[value]} type="radialBar" height={height} />;
};

// Main Component
const WeatherDashboard = () => {
  const fakeData = {
    "2023-01": [
      { month: "Jan", temperature: 10, precipitation: 50 },
      { month: "Feb", temperature: 12, precipitation: 40 },
      { month: "Mar", temperature: 15, precipitation: 60 },
    ],
    "2023-02": [
      { month: "Apr", temperature: 18, precipitation: 80 },
      { month: "May", temperature: 22, precipitation: 70 },
      { month: "Jun", temperature: 25, precipitation: 90 },
    ],
    "2023-03": [
      { month: "Jul", temperature: 28, precipitation: 100 },
      { month: "Aug", temperature: 27, precipitation: 110 },
      { month: "Sep", temperature: 24, precipitation: 80 },
    ],
  };

  const [selectedDate, setSelectedDate] = useState("2023-01");
  const [chartData, setChartData] = useState(fakeData[selectedDate]);

  const handleDateChange = (event) => {
    const date = event.target.value;
    setSelectedDate(date);
    setChartData(fakeData[date]);
  };

  return (
    <div className="bg-gradient-to-br from-purple-50 to-white p-6 rounded-lg shadow-lg max-w-5xl mx-auto">
      {/* Header */}
     

      {/* Date Filter */}
      <div className="mb-6">
        <label htmlFor="dateFilter" className="block text-sm font-medium text-gray-600 mb-1">
          Select Date Range
        </label>
        <select
          id="dateFilter"
          value={selectedDate}
          onChange={handleDateChange}
          className="block w-full px-3 py-2 text-sm text-gray-600 border border-gray-300 rounded-lg focus:ring-purple-500 focus:border-purple-500 transition-all"
        >
          <option value="2023-01">Jan - Mar 2023</option>
          <option value="2023-02">Apr - Jun 2023</option>
          <option value="2023-03">Jul - Sep 2023</option>
        </select>
      </div>

      {/* Weather Chart */}
      <div className="mb-6">
        <div className="bg-white p-4 rounded-lg shadow-sm hover:shadow-md transition-shadow">
          <div className="flex items-center mb-4">
            <FontAwesomeIcon icon={faThermometerHalf} className="text-xl text-purple-500 mr-2" />
            <h2 className="text-lg font-semibold text-gray-700">Weather Overview</h2>
          </div>
          <WeatherChart data={chartData} />
        </div>
      </div>

      {/* Gauges */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="bg-white p-4 rounded-lg shadow-sm hover:shadow-md transition-shadow">
          <div className="flex items-center mb-4">
            <FontAwesomeIcon icon={faTint} className="text-xl text-purple-500 mr-2" />
            <h2 className="text-lg font-semibold text-gray-700">Humidity</h2>
          </div>
          <Gauge value={65} label="Humidity" />
        </div>

        <div className="bg-white p-4 rounded-lg shadow-sm hover:shadow-md transition-shadow">
          <div className="flex items-center mb-4">
            <FontAwesomeIcon icon={faWind} className="text-xl text-purple-500 mr-2" />
            <h2 className="text-lg font-semibold text-gray-700">Air Quality Index (AQI)</h2>
          </div>
          <Gauge value={45} label="AQI" />
        </div>
      </div>
    </div>
  );
};

export default WeatherDashboard;