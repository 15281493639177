import React from "react";
import ReactApexChart from "react-apexcharts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserSecret } from "@fortawesome/free-solid-svg-icons";

const SuspectedPerson = () => {
  const suspectData = {
    series: [5, 10, 15], // Example data for zones A, B, C
    labels: ["Zone A", "Zone B", "Zone C"],
  };

  const options = {
    chart: {
      type: "bar",
      height: 350,
    },
    colors: ["#6F42C1", "#9F7AEA", "#D6BCFA"],
    xaxis: { categories: suspectData.labels },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
  };

  return (
    <div className="bg-white rounded-lg shadow-lg p-4 h-full w-full flex flex-col">
      <div className="flex items-center mb-4">
        <FontAwesomeIcon icon={faUserSecret} className="text-purple-600 text-2xl mr-3" />
        <h2 className="text-xl font-bold text-purple-900">Suspected Person</h2>
      </div>
      <div className="flex-1 flex items-center justify-center">
        <ReactApexChart options={options} series={[{ data: suspectData.series }]} type="bar" height={230} />
      </div>
    </div>
  );
};

export default SuspectedPerson;