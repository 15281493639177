import React from "react";
import ReactApexChart from "react-apexcharts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboardList } from "@fortawesome/free-solid-svg-icons";

const OpenCountersPerAirline = () => {
  // Fake data for open counters per airline
  const countersData = {
    series: [
      { name: "Open Counters", data: [5, 8, 6, 7, 9] }, // Counters per airline
    ],
    categories: ["Airline A", "Airline B", "Airline C", "Airline D", "Airline E"],
  };

  // Chart options
  const options = {
    chart: { type: "bar", toolbar: { show: false } },
    colors: ["#6F42C1"], // Purple
    xaxis: { categories: countersData.categories },
    plotOptions: { bar: { horizontal: false, columnWidth: "55%" } },
    dataLabels: { enabled: true },
    tooltip: { theme: "dark" },
  };

  return (
    <div className="bg-white rounded-lg shadow-lg p-3 h-full w-full flex flex-col">
      <div className="flex items-center mb-4">
        <FontAwesomeIcon icon={faClipboardList} className="text-purple-600 text-2xl mr-3" />
        <h2 className="text-2xl font-bold text-purple-900">Open Counters Per Airline</h2>
      </div>
      <ReactApexChart
        options={options}
        series={countersData.series}
        type="bar"
        height="230px"
      />
    </div>
  );
};

export default OpenCountersPerAirline;