import React from "react";
import ReactApexChart from "react-apexcharts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVideo } from "@fortawesome/free-solid-svg-icons";

const VideoAnalyticAlertCount = () => {
  const alertData = {
    series: [45, 30, 25], // Example data for zones A, B, C
    labels: ["Zone A", "Zone B", "Zone C"],
  };

  const options = {
    chart: {
      type: "donut",
      height: 350,
    },
    colors: ["#6F42C1", "#9F7AEA", "#D6BCFA"],
    labels: alertData.labels,
    legend: {
      position: "bottom",
    },
    dataLabels: {
      enabled: true,
      formatter: (val) => `${val}%`,
      style: {
        colors: ["#fff"],
      },
    },
  };

  return (
    <div className="bg-white rounded-lg shadow-lg p-4 h-full w-full flex flex-col">
      <div className="flex items-center mb-4">
        <FontAwesomeIcon icon={faVideo} className="text-purple-600 text-2xl mr-3" />
        <h2 className="text-xl font-bold text-purple-900">Video Analytic Alerts </h2>
      </div>
      <div className="flex-1 flex items-center justify-center">
        <ReactApexChart options={options} series={alertData.series} type="donut" height={200} />
      </div>
    </div>
  );
};

export default VideoAnalyticAlertCount;