import React from "react";

const KPICard = ({ title, value, icon }) => {
  return (
    <div className="bg-white rounded-lg shadow-sm p-2 transform transition-all hover:scale-105 hover:shadow-purple-500/20 hover:border-purple-200 hover:border relative max-h-20 overflow-hidden">
      {/* Top Row: Icon */}
      <div className="flex justify-start">
        <div className="flex justify-center items-center w-8 h-8 bg-purple-100 rounded-md">
          {React.cloneElement(icon, { className: "w-4 h-4 text-purple-700" })}
          
        </div>
        <p className="text-2xl font-bold text-purple-900 ml-24">{value}</p>

      </div>

      {/* Bottom Row: Title and Value */}
      <div className="flex justify-between items-end ">
        {/* Title */}
        <h3 className="text-xs font-semibold text-purple-700 uppercase tracking-wider">
          {title}
        </h3>

        {/* Value */}
      </div>

      {/* Decorative Gradient Border */}
      <div className="absolute bottom-0 left-0 right-0 h-1 bg-gradient-to-r from-purple-400 to-purple-600 rounded-b-lg"></div>
    </div>
  );
};

export default KPICard;