import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlane,
  faCloudSun,
  faExclamationTriangle,
  faUsers,
  faShieldAlt,
} from '@fortawesome/free-solid-svg-icons';

// Import your logo image (replace 'logo.png' with your actual image file)
import logo from '../Components/test.png';

const Sidebar = () => {
  const navigate = useNavigate();

  // Function to handle button clicks
  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <div className="flex flex-col w-64 bg-purple-800 text-white h-screen p-2 rounded-r-lg shadow-lg">
      {/* Logo Section */}
      <div className="flex items-center justify-center mb-10">
        {/* Replace the text with an image */}
        <img src={logo} alt="Logo" className="h-20 w-auto" /> {/* Adjust height and width as needed */}
      </div>

      {/* Navigation Links */}
      <nav className="flex-1">
        <ul className="">
          {/* Flight Information */}
          <li>
            <button
              onClick={() => handleNavigation('/stc')}
              className="w-full flex items-center p-2 hover:bg-purple-700 rounded-lg transition duration-300 ease-in-out"
            >
              <FontAwesomeIcon icon={faPlane} className="mr-3 text-purple-300" />
              <span className="text-sm font-medium">Flight Information</span>
            </button>
          </li>

          {/* Weather Forecast */}
      

          {/* Issues or Alerts */}
          <li>
            <button
              onClick={() => handleNavigation('/eventsandalerts')}
              className="w-full flex items-center p-2 hover:bg-purple-700 rounded-lg transition duration-300 ease-in-out"
            >
              <FontAwesomeIcon icon={faExclamationTriangle} className="mr-3 text-purple-300" />
              <span className="text-sm font-medium">Issues or Alerts</span>
            </button>
          </li>

          {/* Passenger Flow */}
          <li>
            <button
              onClick={() => handleNavigation('/passengerflow')}
              className="w-full flex items-center p-2 hover:bg-purple-700 rounded-lg transition duration-300 ease-in-out"
            >
              <FontAwesomeIcon icon={faUsers} className="mr-3 text-purple-300" />
              <span className="text-sm font-medium">Passenger Flow</span>
            </button>
          </li>
          <li>
            <button
              onClick={() => handleNavigation('/weather')}
              className="w-full flex items-center p-2 hover:bg-purple-700 rounded-lg transition duration-300 ease-in-out"
            >
              <FontAwesomeIcon icon={faCloudSun} className="mr-3 text-purple-300" />
              <span className="text-sm font-medium">Weather Forecast</span>
            </button>
          </li>

          {/* Security Systems */}
          <li>
            <button
              onClick={() => handleNavigation('/SecuritySystem')}
              className="w-full flex items-center p-2 hover:bg-purple-700 rounded-lg transition duration-300 ease-in-out"
            >
              <FontAwesomeIcon icon={faShieldAlt} className="mr-3 text-purple-300" />
              <span className="text-sm font-medium">Security Systems</span>
            </button>
          </li>
        </ul>
      </nav>

      {/* Footer Section */}
      <div className="mt-6 text-center text-sm text-purple-300">
        <p>© 2025 OODA world</p>
        <p>All rights reserved</p>
      </div>
    </div>
  );
};

export default Sidebar;